import { FC, useContext } from 'react';
import { ContractContext } from '../context/ContractContext';
import { TextBox } from './TextBox';

export const AccountMessage: FC = (): JSX.Element => {
  const { currentAccount } = useContext(ContractContext);
  const { message } = useContext(ContractContext);

  return (
    <div>
      <TextBox heading="Account" text={currentAccount} />
      <TextBox heading="Message" text={message} />
    </div>
  );
};

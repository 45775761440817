import React, { FC, useState, useEffect, useRef } from 'react';
import { HelloWorld__factory, HelloWorld } from '../contracts/types';
import { CONTRACT_ADDRESS } from '../utils/constants';
import { ethers } from 'ethers';

const { ethereum } = window as any;

type AccountContextType = {
  currentAccount: string;
  message: string;
  changeMessage: (msg: string) => void;
};

export const ContractContext = React.createContext<AccountContextType>({
  currentAccount: '',
  message: '',
  changeMessage: () => {},
});

export const ContractProvider: FC<{
  defaultCurrentAccount: string;
}> = ({ children, defaultCurrentAccount }) => {
  const [currentAccount, setCurrentAccount] = useState(defaultCurrentAccount);
  const [message, setMessage] = useState('');
  const helloWorldRef = useRef<HelloWorld>();

  const requestAccounts = async () => {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    setCurrentAccount(accounts[0]);
  };

  const getMessage = async () => {
    const msg = await helloWorldRef.current!.getMessage();
    setMessage(msg);
  };

  const changeMessage = async (msg: string) => {
    await helloWorldRef.current!.setMessage(msg).then(
      () => {
        setMessage(msg);
      },
      (reason) => {
        alert(reason.message);
      }
    );
  };

  useEffect(() => {
    async function load() {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      helloWorldRef.current = HelloWorld__factory.connect(
        CONTRACT_ADDRESS,
        signer
      );

      if (ethereum) {
        ethereum.on('accountsChanged', () => {
          requestAccounts();
        });
      }

      helloWorldRef.current!.on(
        helloWorldRef.current!.filters.MessageChanged(undefined, undefined),
        (_, newMessage) => {
          setMessage(newMessage);
        }
      );

      requestAccounts();
      getMessage();
    }

    load();
  }, []);

  return (
    <ContractContext.Provider
      value={{ currentAccount, message, changeMessage }}
    >
      {children}
    </ContractContext.Provider>
  );
};

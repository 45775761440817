import { FC, ChangeEvent, FormEvent, useContext, useState } from 'react';
import { ContractContext } from '../context/ContractContext';
import { InputGroup, InputRightElement, Button, Input } from '@chakra-ui/react';

export const ChangeMessage: FC = (): JSX.Element => {
  const { changeMessage } = useContext(ContractContext);
  const [newMessage, setNewMsg] = useState<string>('');

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    changeMessage(newMessage);
  };
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewMsg(e.target.value);
  };

  return (
    <form onSubmit={onSubmit}>
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          type="text"
          placeholder="Enter a new message..."
          onChange={onChange}
          value={newMessage}
        />
        <InputRightElement width="4.5rem">
          <Button type="submit" h="1.75rem" size="sm">
            Update
          </Button>
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

import { ChakraProvider, Stack } from '@chakra-ui/react';
import { ContractProvider } from './context/ContractContext';
import { AccountMessage } from './components/AccountMessage';
import { ChangeMessage } from './components/ChangeMessage';

function App() {
  return (
    <div>
      <ChakraProvider>
        <ContractProvider defaultCurrentAccount="">
          <Stack spacing={8}>
            <AccountMessage />
            <ChangeMessage />
          </Stack>
        </ContractProvider>
      </ChakraProvider>
    </div>
  );
}

export default App;

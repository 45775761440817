import { FC } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

export const TextBox: FC<{ heading: string; text: string }> = ({
  heading,
  text,
}): JSX.Element => {
  return (
    <Box p={5} shadow="md" borderWidth="1px">
      <Heading fontSize="xl">{heading}</Heading>
      <Text mt={4}>{text}</Text>
    </Box>
  );
};
